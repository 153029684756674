import {HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {RequestsMiddlewareService} from "./requests-middleware.service";
import {inject} from "@angular/core";
import {catchError, switchMap, throwError, timer} from "rxjs";
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";

export const authorizationInterceptor: HttpInterceptorFn = (request, next) => {

  const requests = inject(RequestsMiddlewareService);
  const router = inject(Router);

  const handle401Error = (request: HttpRequest<unknown>, next: HttpHandlerFn) => {

    return requests.refreshToken().pipe(
      switchMap(() => {
        console.log("Refreshing token");

        return timer(1000).pipe(
          switchMap(() => {
            console.log("Performing again same request");
            return next(request)
          })
        );
      })
    );
  }

  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        if (request.url.includes(environment.endpoints.refreshToken.path)) {
          router.navigate([environment.urls.userLogin]);
          return throwError(() => error);
        }
        if (request.url.includes(environment.endpoints.login.path)) {
          return throwError(() => error);
        }

        return handle401Error(request, next);
      } else {
        return throwError(() => error);
      }
    })
  );
};
