import {Component, OnInit} from '@angular/core';
import {SpinnerComponent} from "../../../common-components/toasts/spinner/spinner.component";
import {RequestsMiddlewareService} from "../../../services/requests-middleware.service";
import {ActivatedRoute} from "@angular/router";
import {RolesService} from "../../../services/roles.service";

@Component({
  selector: 'app-role',
  standalone: true,
  imports: [
    SpinnerComponent
  ],
  templateUrl: './role.component.html',
  styleUrl: './role.component.css'
})
export class RoleComponent implements OnInit {
  roleId!: string

  constructor(private requests: RequestsMiddlewareService, private route: ActivatedRoute, protected rolesService: RolesService) {
  }

  ngOnInit(): void {
    const roleId: string | null = this.route.snapshot.paramMap.get("role")
    if (roleId == null) {
      throw new Error("Can not find role in path")
    } else {
      this.roleId = roleId
    }
  }

  setPermission(toAdd: boolean, permission: string): void {
    if (toAdd) {
      this.requests.createRequest<void>(
        "updateRole",
        {
          role: this.roleId,
          permissionsToAdd: [permission]
        },
        null,
        "text"
      ).subscribe(() =>
        console.log("done")
      )
    } else {
      this.requests.createRequest<void>(
        "updateRole",
        {
          role: this.roleId,
          permissionsToRemove: [permission]
        },
        null,
        "text"
      ).subscribe(() =>
        console.log("done")
      )
    }
  }
}
