@if (this.rolesService.roles != undefined) {
  @if (this.rolesService.roles.get(this.roleId) != undefined) {
    <h1>{{ this.rolesService.roles.get(this.roleId)?.label }}</h1>
    <h3>{{ this.roleId }}</h3>
    <h5>{{ this.rolesService.roles.get(this.roleId)?.description }}</h5>
    <div class="table-responsive">
      <table class="table table-striped table-hover align-middle">
        <thead>
        <tr>
          <th scope="col">Permesso</th>
          <th scope="col"></th>
        </tr>
        </thead>
        <tbody class="table-group-divider">
          @for (permission of this.rolesService.permissions; track permission) {
            <tr>
              <td>{{ permission }}</td>
              <td>
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" #checkbox
                         [checked]="this.rolesService.roles.get(this.roleId)?.permissions?.includes(permission)"
                         (click)="setPermission(checkbox.checked, permission)">
                </div>
              </td>
            </tr>
          } @empty {
            <tr>
              <td colspan="4" class="text-center">
                <app-spinner/>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  } @else {
    <p>Non trovato</p>
  }
} @else {
  <app-spinner/>
}

<button class="btn btn-lg btn-outline-primary" (click)="this.rolesService.getRoles()">Refresh</button>

