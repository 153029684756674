<div class="table-responsive">
  <table class="table table-striped table-hover align-middle">
    <thead>
    <tr>
      <th scope="col">Nome</th>
      <th scope="col">Cognome</th>
      <th scope="col">Email</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody class="table-group-divider">
      @for (user of users; track user.userId) {
        <tr>
          <td>{{ user.name }}</td>
          <td> {{ user.surname }}</td>
          <td> {{ user.email }}</td>
          <td>
            <button type="button" class="btn btn-secondary btn-sm mx-2">
              <i class="bi bi-pencil-fill"></i>
            </button>
            <button type="button" class="btn btn-danger btn-sm mx-2">
              <i class="bi bi-trash-fill"></i>
            </button>
          </td>
        </tr>
      } @empty {
        <tr>
          <td colspan="4" class="text-center">
            <app-spinner/>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

<form (ngSubmit)="createUser()">
  <div class="form-floating mb-3">
    <input type="email" name="email" class="form-control" id="email" placeholder="name@example.com" [(ngModel)]="email">
    <label for="email">Email</label>
  </div>
  <div class="form-floating mb-3">
    <input type="text" name="name" class="form-control" id="name" placeholder="Marco" [(ngModel)]="name">
    <label for="name">Nome</label>
  </div>
  <div class="form-floating mb-3">
    <input type="text" name="surname" class="form-control" id="surname" placeholder="Barbaro" [(ngModel)]="surname">
    <label for="surname">Cognome</label>
  </div>
  <button type="submit" class="btn btn-primary">Submit</button>
</form>
