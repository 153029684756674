import {Injectable} from '@angular/core';
import {RequestsMiddlewareService} from "./requests-middleware.service";
import {ElasticSearchResponse} from "../data-classes/elastic-search-response";
import {Role} from "../data-classes/Role";

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  roles!: Map<string, Role>
  permissions!: string[]

  constructor(private requests: RequestsMiddlewareService) {
    this.getRoles()
    this.getPermissions()
  }

  getPermissions(): void {
    this.requests.createRequest<string[]>(
      "getPermissions",
      null,
      null
    ).subscribe(response =>
      this.permissions = response
    )
  }

  getRoles(): void {
    this.requests.createRequest<ElasticSearchResponse<Role>>(
      "getRoles",
      null,
      null
    ).subscribe(elastic =>
      this.roles = new Map(elastic.hits.hits.map(document => [document._id, document._source]))
    )
  }
}
