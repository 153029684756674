<button class="btn btn-lg btn-outline-primary" (click)="open(content)">Crea un ruolo</button>
<hr/>
<div class="table-responsive">
  <table class="table table-striped table-hover align-middle">
    <thead>
    <tr>
      <th scope="col">Identificativo</th>
      <th scope="col">Nome</th>
      <th scope="col">Descrizione</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody class="table-group-divider">
      @for (role of this.rolesService.roles; track role[0]) {
        <tr>
          <td>{{ role[0] }}</td>
          <td>{{ role[1].label }}</td>
          <td>{{ role[1].description }}</td>
          <td>
            <button type="button" class="btn btn-secondary btn-sm mx-2" (click)="goToRolePage(role[0])">
              <i class="bi bi-pencil-fill"></i>
            </button>
            <button type="button" class="btn btn-danger btn-sm mx-2" (click)="deleteRole(role[0])">
              <i class="bi bi-trash-fill"></i>
            </button>
          </td>
        </tr>
      } @empty {
        <tr>
          <td colspan="4" class="text-center">
            <app-spinner/>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Crea un ruolo</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-floating mb-3">
        <input type="text" name="role-id" class="form-control" id="role-id" placeholder="name@example.com"
               [(ngModel)]="creationRoleId" required>
        <label for="role-id">Identificativo</label>
      </div>
      <div class="form-floating mb-3">
        <input type="text" name="role-label" class="form-control" id="role-label" placeholder="Marco"
               [(ngModel)]="creationRoleLabel" required>
        <label for="role-label">Nome</label>
      </div>
      <div class="form-group form-floating mb-3">
        <textarea type="text" name="role-description" rows="2" class="form-control" id="role-description"
                  placeholder="Barbaro" [(ngModel)]="creationRoleDescription"></textarea>
        <label for="role-description">Descrizione</label>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="createRole()">Crea</button>
  </div>
</ng-template>

<ng-template #standardTpl> I'm a standard toast</ng-template>
