import {Component} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {RequestsMiddlewareService} from "../../services/requests-middleware.service";
import {NgbModal, NgbModalConfig, NgbToast} from '@ng-bootstrap/ng-bootstrap';
import {SpinnerComponent} from "../../common-components/toasts/spinner/spinner.component";
import {Router, RouterLink} from "@angular/router";
import {environment} from "../../../environments/environment";
import {RolesService} from "../../services/roles.service";

//TODO gestire il ciclo di vita di service rolesService. se non lo metto in providers non me lo aggiorna neppure con
// cambio tenant. se lo metto in ogni componenete non lo syasha. forse con un modulo?
@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [
    FormsModule,
    SpinnerComponent,
    NgbToast,
    RouterLink
  ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.css'
})
export class RolesComponent {
  creationRoleId: string = ""
  creationRoleLabel: string = ""
  creationRoleDescription?: string

  constructor(private requests: RequestsMiddlewareService, config: NgbModalConfig, private modalService: NgbModal, private router: Router, protected rolesService: RolesService) {
    config.backdrop = 'static';
    config.centered = true;
  }

  goToRolePage(roleId: string): void {
    this.router.navigate([environment.urls.roles, roleId])
  }

  deleteRole(roleId: string): void {
    this.requests.createRequest<void>(
      "deleteRole",
      null,
      {
        id: roleId
      },
      "text"
    ).subscribe(() =>
      //TODO toast
      console.log("fatto")
    )
  }

  open(content: any) {
    this.modalService.open(content);
  }

  createRole(): void {
    console.log("onSubmit")
    this.requests.createRequest<void>(
      "createRole",
      {
        role: this.creationRoleId,
        label: this.creationRoleLabel,
        description: this.creationRoleDescription,
        permissions: []
      },
      null,
      'text'
    ).subscribe({
        //TODO toast e ripulire fields
        next: () => {
          this.modalService.dismissAll();
        },
        error: () => {
          // this.modalService.dismissAll();
        }
      }
    )
  }

}
