<main class="h-100">
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-3 text-bg-dark">
        <a routerLink="./" class="text-white text-decoration-none">
          <i class="bi pe-none me-2" width="40" height="32"></i>
          <span class="fs-4">Areson</span>
        </a>
        <hr>
        <ul class="nav nav-pills flex-column mb-auto">
          <li>
            <a routerLink="./home" class="nav-link text-white" routerLinkActive="active" ariaCurrentWhenActive="page">
              <i class="bi pe-none me-2 bi-house-door-fill"></i>
              Home
            </a>
          </li>
          <li>
            <a routerLink="./shifts" class="nav-link text-white" routerLinkActive="active" ariaCurrentWhenActive="page">
              <i class="bi pe-none me-2 bi-calendar-week-fill"></i>
              Turni
            </a>
          </li>
          <li>
            <a routerLink="./users" class="nav-link text-white" routerLinkActive="active" ariaCurrentWhenActive="page">
              <i class="bi pe-none me-2 bi-people-fill"></i>
              Utenti
            </a>
          </li>
          <li>
            <a routerLink="./locations" class="nav-link text-white" routerLinkActive="active" ariaCurrentWhenActive="page">
              <i class="bi pe-none me-2 bi-pin-map-fill"></i>
              Sedi
            </a>
          </li>
          <li>
            <a routerLink="./vehicles" class="nav-link text-white" routerLinkActive="active" ariaCurrentWhenActive="page">
              <i class="bi pe-none me-2 bi-car-front-fill"></i>
              Mezzi
            </a>
          </li>
          <li>
            <a routerLink="./roles" class="nav-link text-white" routerLinkActive="active" ariaCurrentWhenActive="page">
              <i class="bi pe-none me-2 bi-person-badge-fill"></i>
              Ruoli
            </a>
          </li>
          <li>
            <a routerLink="./profile" class="nav-link text-white" routerLinkActive="active" ariaCurrentWhenActive="page">
              <i class="bi pe-none me-2 bi-person-fill"></i>
              Profilo
            </a>
          </li>
        </ul>
        <hr>
      </div>

      <div class="col-9">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</main>
