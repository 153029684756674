import {Routes} from '@angular/router';
import {ErrorNotFoundComponent} from "./error-not-found/error-not-found.component";
import {UsersComponent} from "./tenant-page/users/users.component";
import {TenantPageComponent} from "./tenant-page/tenant-page.component";
import {HomeComponent} from "./tenant-page/home/home.component";
import {RolesComponent} from "./tenant-page/roles/roles.component";
import {ShiftsComponent} from "./tenant-page/shifts/shifts.component";
import {LocationsComponent} from "./tenant-page/locations/locations.component";
import {VehiclesComponent} from "./tenant-page/vehicles/vehicles.component";
import {ProfileComponent} from "./tenant-page/profile/profile.component";
import {RoleComponent} from "./tenant-page/roles/role/role.component";

export const routes: Routes = [
  {path: "user", loadChildren: () => import("./module-user/user.module").then(m => m.UserModule)},
  {
    path: "tenant", component: TenantPageComponent, children: [
      {path: "", redirectTo: "home", pathMatch: "full"},
      {path: "home", component: HomeComponent},
      {path: "shifts", component: ShiftsComponent},
      {path: "users", component: UsersComponent},
      {path: "locations", component: LocationsComponent},
      {path: "vehicles", component: VehiclesComponent},
      {path: "roles", component: RolesComponent},
      {path: "roles/:role", component: RoleComponent},
      {path: "profile", component: ProfileComponent}
    ]
  },
  {path: "", redirectTo: "/user/login", pathMatch: "full"},
  {path: "**", component: ErrorNotFoundComponent, title: "Areson - Pagina non trovata"}
];
