import {Component, OnInit} from '@angular/core';
import {RequestsMiddlewareService} from "../../services/requests-middleware.service";
import {FormsModule} from "@angular/forms";
import {ElasticSearchMultiResponse} from "../../data-classes/elastic-search-response";
import {UserTenantInfo} from "../../data-classes/user-tenant-info";
import {SpinnerComponent} from "../../common-components/toasts/spinner/spinner.component";

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    FormsModule,
    SpinnerComponent
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css'
})
export class UsersComponent implements OnInit {
  users!: UserTenantInfo[]
  email: string = ""
  name: string = ""
  surname: string = ""

  constructor(private requests: RequestsMiddlewareService) {
  }

  ngOnInit(): void {
    this.requests.createRequest<ElasticSearchMultiResponse>(
      "getUsers",
      null,
      null
    ).subscribe(elastic =>
      this.users = elastic.responses[0].hits.hits.map(document => document._source)
    )
  }

  createUser(): void {
    console.log("onSubmit")
    this.requests.createRequest<void>(
      "createUser",
      {
        email: this.email,
        password: "vetro00",
        name: this.name,
        surname: this.surname
      },
      null,
      'text'
    ).subscribe(
      () => console.log("successo")
    )
  }
}
